$selected-background: #7cbc7c;
$selected-outline: #008000;
$master-background: #ff810085;
$master-outline: #f57c00;
$sub-background: #fbe190;
$sub-outline: #ffca28;
$client-background: #038af87a;
$client-outline: #038af8;

section.orgChart-section {
  position: relative;
  height: 800px;
  overflow: hidden !important;

  .nodes-wrapper {
    transition: all 0.3s ease-in-out;
  }
  .zoom-btns {
    display: flex;
    flex-direction: column;
    height: 105px;
    width: 30px;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    bottom: 30px;
    right: 30px;
    span {
      font-size: 12px;
      color: gray;
      font-weight: bold;
    }
    button {
      width: 40px;
      height: 40px;
      font-size: 20px;
      font-weight: bold;
      background: #4e7da2;
      color: white;
      border-radius: 50% !important;
      transition: all 0.3s ease;
      border: none;
      outline: none;
      &:hover {
        background: #2b475e !important;
      }
    }
  }
}

.node-card {
  width: 155px;
  height: 155px;
  border-radius: 15px;
  background-color: white;
  border: 1px solid lightgrey;
  text-align: left;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  .node-card-container {
    flex: 1;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 5px solid;
    background-size: contain;
    &.Selected {
      border-color: $selected-outline;
      .node-card-header,
      .node-card-footer {
        background-color: $selected-background;
      }
      &:hover {
        box-shadow: 0px 0px 18px -2px $selected-background;
      }
    }
    &.Master {
      border-color: $master-outline;
      .node-card-header,
      .node-card-footer {
        background-color: $master-background;
      }
      &:hover {
        box-shadow: 0px 0px 18px -2px $master-background;
      }
    }
    &.IB {
      border-color: $sub-outline;
      .node-card-header,
      .node-card-footer {
        background-color: $sub-background;
      }
      &:hover {
        box-shadow: 0px 0px 18px -2px $sub-background;
      }
    }
    &.Client {
      border-color: $client-outline;
      .node-card-header,
      .node-card-footer {
        background-color: $client-background;
      }
      &:hover {
        box-shadow: 0px 0px 18px -2px $client-background;
      }
    }

    > div {
      padding: 5px 10px;
      height: 30px;
    }
    .node-card-header,
    .node-card-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px 5px;
      border-radius: 12px 12px 0 0;
      font-size: 0.9rem;
      color: #333;
      font-weight: 600;
      > span.structure-name {
        max-width: 85px;
        white-space: nowrap;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .node-card-footer {
      border-radius: 0 0 12px 12px;
      font-size: 0.8rem;
      font-weight: 500;
    }
  }
}

.orgchart {
  background-image: none !important;
}
.orgchart-container {
  border: none !important;
  height: 800px !important;
}

.orgchart > ul > li > ul li::before,
.orgchart > ul > li > ul li > .oc-node::before,
.orgchart ul li .oc-node:not(:only-child)::after {
  border-color: #666 !important;
  background-color: #666;
}

.orgchart ul li {
  margin: 0 10px;
}
.orgchart > ul > li > ul li::before {
  width: calc(100% + 20px);
}
.orgchart > ul > li > ul li:first-child::before {
  width: calc(50% + 20px);
  border-top-left-radius: 50px;
  left: calc(50%) !important;
}
.orgchart > ul > li > ul li:last-child::before {
  border-top-right-radius: 50px;
}
.orgchart > ul > li > ul li:only-child::before {
  width: 4px !important;
}
.orgchart > ul > li > ul li > .oc-node::before {
  height: 34px !important;
}
.orgchart > ul > li > ul li > .oc-node::after,
.orgchart ul li .oc-node:not(:only-child)::after {
  height: 34px !important;
}
.orgchart .oc-node .bottomEdge {
  bottom: 16px !important;
}
.orgchart .oc-node .topEdge {
  top: 16px !important;
}
.orgchart ul li .oc-node:hover,
.orgchart ul li .oc-node.selected {
  background-color: white !important;
}

.orgchart .oc-node .oc-edge::before {
  border-color: black !important;
  &:hover {
    border-color: green !important;
  }
}

.node-backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0%;
  top: 0;
  background-color: #00000045;
  z-index: 1120;
}
.node-modal {
  position: fixed;
  z-index: 1121;
  background: white;
  min-height: fit-content;
  right: 0;
  top: 50%;
  width: 360px;
  border-radius: 20px 0 0 20px;
  padding: 20px;
  overflow: hidden;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
  transform: translateX(360px) translateY(-50%);
  animation: slide 0.4s forwards;
  animation-delay: 0s;

  > section {
    border-radius: 50%;
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    border: 1px solid #d3d3d3f2;
    margin: auto;
    margin-bottom: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  > div.info {
    line-height: 1.2;
    text-align: center;
    h3 {
      margin-bottom: 0px;
    }
    small {
      color: gray;
    }
    margin-bottom: 30px;
  }
  > div {
    margin-bottom: 5px;
    > b {
      border-radius: 4px 0 0 4px;
      padding: 5px 10px;
      display: inline-block;
      // margin-right: 10px;
    }
    > span {
      padding: 2px 0px;
      font-size: 14px;
    }
  }
  > button {
    display: block;
    padding: 8px;
    background-color: #395b77;
    color: white;
    border-radius: 5px;
    width: 100%;
    border: none;
    margin-top: 30px;
  }
}

@keyframes slide {
  0% {
    transform: translateX(360px) translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0) translateY(-50%);
  }
}
